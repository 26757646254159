// src/components/KLA/KLA.js
import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import { FaHome, FaPen, FaDollarSign } from "react-icons/fa";
import KLAIntro from "./KLAIntro";
import KLAInput from "./KLAInput_r1";
import KLAResult from "./KLAResult";
import KLANotice from "./KLANotice";
import "./KLA.css";

function KLA() {
  return (
    <div className="kla-container">
      <div className="sidebar">
        <ul>
          <li>
            <Link to="/kla/intro">
              <FaHome /> 소개 및 분석방법
            </Link>
          </li>
          <li>
            <Link to="/kla/notice">
              <FaPen /> 신규발화분석
            </Link>
          </li>
          {/* PDF 링크 추가 */}
          <li>
            <a
              href="/kla_pricing.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaDollarSign /> KLA Pricing
            </a>
          </li>
        </ul>
      </div>
      <div className="content">
        <Routes>
          <Route path="/" element={<KLAIntro />} />
          <Route path="/intro" element={<KLAIntro />} />
          <Route path="/input" element={<KLAInput />} />
          <Route path="/notice" element={<KLANotice />} />
          <Route path="/result" element={<KLAResult />} />
        </Routes>
      </div>
    </div>
  );
}

export default KLA;
